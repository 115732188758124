.findmeContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 150px;
            gap: 25px;
}

.findmeBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 280px;
            background: var(--boxColor);
            border-radius: 12px;
}

.findmeBox div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px;
            gap: 10px;
}

.findmeBox a {
            background: var(--themeColor);
            width: 80%;
            height: 45px;
            border-radius: 8px;
            text-decoration: none;
            margin: 20px;
}

.findmeIcon {
            font-size: 36px;
            color: var(--themeColor);
}

.findmeH1 {
            color: var(--themeColor);
}

@media screen and (max-width: 1000px) {
            .findmeContainer {
                        flex-direction: column;
                        gap: 50px;
            }
}