.SkillsContener {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 10%;
}

.SkillsContener h1 {
            font-size: 80px;
}

.skillBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: var(--boxColor);
            margin-top: 60px;
            width: 900px;
            border-radius: 12px;
}


.progress {
            background-color: #d8d8d8;
            border-radius: 12px;
            position: relative;
            height: 60px;
            width: 800px;
}

.skileRank {
            margin: 30px;

}

.skileRank p {
            text-align: start;
            font-size: 42px;

}

.skillBox h2 {
            font-size: 42px;
            margin: 20px;
}


.progressDone {
            background: var(--themeColor);
            box-shadow: 0 3px 3px -5px var(--themeColor), 0 2px 5px var(--themeColor);
            border-radius: 12px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 0;
            opacity: 0;
            transition: 1s ease 0.3s;
}

.otherSillesList {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 60px;
            padding: 40px 0 80px 0;

}

.otherSillesList div {
            color: var(--themeColor);
            font-size: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
}

.otherSillesList div p {
            font-size: 24px;
}


@media screen and (max-width: 1000px) {
            .SkillsContener h1 {
                        font-size: 60px;
            }

            .skillBox {
                        width: 280px;
            }

            .skileRank p {
                        font-size: 24px;

            }

            .progress {
                        height: 30px;
                        width: 240px;
            }

            .skillBox h2 {
                        font-size: 24px;

            }

            .otherSillesList {
                        grid-template-columns: repeat(2, 1fr);
                        gap: 20px;
            }

}